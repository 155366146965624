import React, { useMemo, useState } from "react"
import { useQuery } from "react-query"
import { useParams, useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import { useMediaQuery, useTheme } from "@material-ui/core"

import api from "@/services/api"
import apiEndPoints from "@/consts/apiEndPoints"
import { translate } from "@/_i18n"
import {
  allZero,
  copyObject,
  handleUnprocessableEntity,
} from "@/services/utils"
import { isBadRequest } from "@/services/errorHandlers"
import { usePagination, useSortData } from "@/hooks"
import { confirmation } from "@/components/Confirmation"

import ChevronLeft from "@material-ui/icons/ChevronLeft"
import Add from "@material-ui/icons/Add"
import DeleteIcon from "@material-ui/icons/Delete"

import ContentPage from "@/components/ContentPage"
import Button from "@/components/Button"
import TableOrderItems from "@/components/Orders/TableItems"
import EditIcon from "@/components/Icons/Edit"

import { removeOrderItemConfirm } from "../confirmations"
import { Container, Buttons } from "./styles"
import OrderItemForm from "./FormEdit"

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩

const ORDER_ITEMS_CLIENT_NAME = "order_items"
const ORDER_ITEMS_STALE_TIME = 0
const initialData = {}

//  ╔╦╗╔═╗╔╦╗╦ ╦╔═╗╔╦╗╔═╗
//  ║║║║╣  ║ ╠═╣║ ║ ║║╚═╗
//  ╩ ╩╚═╝ ╩ ╩ ╩╚═╝═╩╝╚═╝

const getOrderItemIsEditable = (order, orderItem) => {
  return order.editable
    ? allZero([orderItem.itped_qtd_baixada, orderItem.itped_qtd_cancelada])
    : false
}

const getStatus = (item) => {
  if (parseInt(item.itped_qtd_baixada) > 0) return "Faturado"
  if (parseInt(item.itped_qtd_cancelada) > 0) return "Cancelado"
  return "Em aberto"
}

export default function Items() {
  const { orderId } = useParams()
  const history = useHistory()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const [current, setCurrent] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [orderItems, setOrderItems] = useState([])

  const { currentSort, onSortChange, sortData } = useSortData({
    initialField: "product.descr",
  })

  const {
    page,
    rowsPerPage,
    onPageChange,
    onRowsPerPageChange,
    calculateNewPaginatorData,
  } = usePagination()

  const {
    data: order,
    isFetching,
    refetch,
  } = useQuery(
    [ORDER_ITEMS_CLIENT_NAME, { orderId }],
    async () => {
      const response = await api.get(apiEndPoints.orders.order(orderId))
      const order = { ...response.data }

      const orderItems = order.items.map((item) => ({
        editable: getOrderItemIsEditable(order, item),
        status: getStatus(item),
        ...item,
      }))

      setOrderItems(orderItems)
      return order
    },
    {
      staleTime: ORDER_ITEMS_STALE_TIME,
      refetchOnWindowFocus: false,
      initialData: { ...initialData },
    }
  )

  const { currentPageRecords } = calculateNewPaginatorData(sortData(orderItems))

  const enableAddButton = useMemo(() => {
    if (!order) return false

    const isUnfulfilled =
      parseInt(order.total_quantity_fulfilled) +
        parseInt(order.total_quantity_cancelled) ===
      0

    return order.editable && isUnfulfilled
  }, [order])

  // ==== API HANDLERS ====

  const createOrderItem = async (orderItem, form) => {
    try {
      await api.post(apiEndPoints.orders.orderItems(orderId), {
        order_item: orderItem,
      })

      setCurrent(null)
      refetch()
    } catch (error) {
      const errors = handleUnprocessableEntity(error)
      form.setErrors(errors)

      if (isBadRequest(error)) {
        setCurrent(null)
        refetch()
      }
    }
  }

  const updateOrderItem = async (orderItem, form) => {
    try {
      await api.put(apiEndPoints.orders.orderItems(orderId, orderItem.id), {
        order_item: orderItem,
      })

      setCurrent(null)
      refetch()
    } catch (error) {
      const errors = handleUnprocessableEntity(error)
      form.setErrors(errors)

      if (isBadRequest(error)) {
        setCurrent(null)
        refetch()
      }
    }
  }

  const deleteOrderItem = async (orderItem) => {
    try {
      await api.delete(apiEndPoints.orders.orderItems(orderId, orderItem.id))

      toast.success("Item excluido com sucesso")
      confirmation.close()

      if (orderItems.length === 1) history.goBack()
      else refetch()
    } catch (error) {
      confirmation.close()
      refetch()
    }
  }

  // ==== HANDLERS ====

  const handleNewOrderItem = async () => {
    setCurrent({})
  }

  const handleEditOrderItem = (orderItem) => {
    setCurrent(copyObject(orderItem))
  }

  const handleClose = () => {
    setCurrent(null)
  }

  const handleSave = async (orderItem, form) => {
    setIsLoading(true)
    if (orderItem.id) await updateOrderItem(orderItem, form)
    else await createOrderItem(orderItem, form)
    setIsLoading(false)
  }

  const handleDeleteOrderItem = async (orderItem) => {
    const isLast = orderItems.length === 1
    const confirmed = await removeOrderItemConfirm(orderItem, isLast)
    if (confirmed) deleteOrderItem(orderItem)
  }

  return (
    <>
      <ContentPage
        showReturn
        title={translate("order.item.title", {
          order: order?.cod_pedido || translate("order"),
        })}
      >
        <Container>
          {/* ==== TABLE ==== */}
          <TableOrderItems
            order={order}
            data={currentPageRecords}
            loading={isFetching}
            currentSort={currentSort}
            count={orderItems.length}
            page={page}
            rowsPerPage={rowsPerPage}
            onSortChange={onSortChange}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            options={{
              paging: true,
              sorting: true,
              total: false,
            }}
            actions={[
              {
                title: (orderItem) =>
                  orderItem?.editable ? translate("edit") : translate("view"),
                icon: (orderItem) => (
                  <EditIcon isEditable={orderItem.editable} fontSize="small" />
                ),
                onClick: handleEditOrderItem,
              },
              {
                title: translate("delete"),
                icon: <DeleteIcon fontSize="small" />,
                onClick: handleDeleteOrderItem,
                getDisabled: (item) => !item.editable,
              },
            ]}
          />
        </Container>
      </ContentPage>
      <Buttons>
        <Button
          size="medium"
          color="primary"
          variant="text"
          startIcon={<ChevronLeft />}
          onClick={() => history.goBack()}
        >
          {translate("back")}
        </Button>

        <Button
          size="medium"
          color="primary"
          variant="contained"
          startIcon={<Add />}
          disabled={!enableAddButton}
          onClick={handleNewOrderItem}
        >
          {translate("new.item")}
        </Button>
      </Buttons>

      {/* ==== FORM ==== */}
      <OrderItemForm
        order={order}
        current={current}
        open={Boolean(current)}
        onClose={handleClose}
        onSave={handleSave}
        loading={isLoading}
        isMobile={isMobile}
      />
    </>
  )
}
